@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

div.StripeElement {
  border: 1px solid #c5ced6;
  border-radius: 4px;
  padding: 13px 16px;
  height: 48px;
  align-items: center;
  outline: none;
}

div.focused {
  border: 2px solid #508ef4;
}
div.invalid {
  border: 2px solid #fa755a;
}

.tooltip {
  background-color: white !important;
  border-radius: 5px !important;
  box-shadow: 0px 5px 15px 1px rgb(182, 182, 182) !important;
  max-width: 350px;
  opacity: 1 !important;
  font-family: "Montserrat";
  padding: 25px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 500;
}
